
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';

// import translationsInEnglish from '/src/i18n/locales/en/translation.json';
// import translationsInSpanish from '/src/i18n/locales/es/translation.json';
// import translationsInCatalan from '/src/i18n/locales/ca/translation.json';

const optionsLanguageDetector = {
  // order and from where user language should be detected
  order: ['localStorage', 'querystring', 'cookie', 'sessionStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],

  // keys or params to lookup language from
  lookupQuerystring: 'lng',
  lookupCookie: 'i18next',
  lookupLocalStorage: 'lang', // 'i18nextLng'
  lookupSessionStorage: 'lang', // 'i18nextLng'
  lookupFromPathIndex: 0,
  lookupFromSubdomainIndex: 0,

  // cache user language on
  caches: ['localStorage', 'cookie'],
  excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)

  // optional expire and domain for set cookie
  cookieMinutes: 10,
  cookieDomain: 'myDomain',

  // optional htmlTag with lang attribute, the default is:
  htmlTag: document.documentElement,

  // optional set cookie options, reference:[MDN Set-Cookie docs](https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie)
  cookieOptions: { path: '/', sameSite: 'strict' },

  // optional conversion function to use to modify the detected language code
  convertDetectedLanguage: 'Iso15897',  // 'Iso15897',
  convertDetectedLanguage: (lng) => lng.substr(0,2) //.replace('-', '_')
}

// the translations
/* const resources = {
  es: {
        translation: translationsInSpanish
      },
  ca: {
        translation: translationsInCatalan
      },
  en: {
        translation: translationsInEnglish
      },
}; */

i18n
  // initReactI18next -- react-i18next
  // passes i18n down to react-i18next
  .use(initReactI18next) 
  // i18next-http-backend
  // loads translations from your server
  // https://github.com/i18next/i18next-http-backend
  .use(Backend)
  // i18next-browser-languagedetector
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  // import LanguageDetector from 'i18next-browser-languagedetector';
  .use(LanguageDetector)
  .init({
    // SELECCIO LLENGUA
    detection: optionsLanguageDetector, // opcions de LanguageDetector
    // lng: 'es', // no fa falta perque se usa detection per detectar el llenguatge per defecte
    fallbackLng: "es", // use de if selected language is not available
    // DECLARACIO DELS ARXIUS DE TRADUCCIONS
    backend: {
      // for all available options read the backend's repository readme file
      loadPath: '/locales/{{lng}}/{{ns}}.json'  // carpeta dins de la carpeta public
    },
    partialBundledLanguages: true, // permet carregar nomes una part dels arxius
    load: 'languageOnly',
    // resources, // resources are important to load translations for the languages.
    // lng: "es", // It acts as default language. When the site loads, content is shown in this language.
    // lng: localStorage.getItem("lang"),  
    debug: true,
    interpolation: {
      escapeValue: false // not needed for react as it escapes by default
    },
    ns: ["translation","map","legal"], // namespaces help to divide huge translations into multiple small files.
    defaultNS: "translation",
    //react: {
      // DESACTIVA EL SUSPENSE -- MITJOR SI ESTA ACTIVAT
      // Turn off the use of React Suspense
    //  useSuspense: false
    //},
    // RUTINES EN CAS QUE NO TROVI UNA TRADUCCIO - xDEVELOPMENT
    saveMissing: true, // for missing key handler to fire
    missingKeyHandler: function (lng, ns, key, fallbackValue) {
    console.log('*** ERROR *** -- i18n -- ERROR - no se ha tobat una entrada --> ', lng, ns, key, fallbackValue);
    }
  });


/*   import { DateTime } from 'luxon';
  i18n.services.formatter.add('DATE_HUGE', (value, lng, options) => {
    return DateTime.fromJSDate(value).setLocale(lng).toLocaleString(DateTime.DATE_HUGE)
  }); */

export default i18n;
