import React from 'react';
import { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { RouterProvider } from "react-router-dom";

import { router } from './routes/AppRoutes';
// import { AuthProvider } from './context/Auth.jsx';
import Loader from '/src/components/common/Loader';

import './styles/styles.scss';
// import './index.css'

ReactDOM.createRoot(document.getElementById('root')).render(
  
  <React.StrictMode>
  <>
    {console.log('*****-> App -- ENVIRONMENT --', import.meta.env.MODE)}

      {/* <AuthProvider> */}

        {/* <Suspense fallback="loading..."> */}
        <Suspense fallback={ <Loader/> }>
          <RouterProvider router={router} />
          {/* <App /> */} 
        </Suspense> 
        
      {/* </AuthProvider> */}
  </>
  </React.StrictMode>, 
)
