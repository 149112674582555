
/** ************************************************
 * initial
 * funcio per inicialitzar les variables 
 * i asegurar que estiguin aunque se accedeixe a una 
 * pantalla directament i no es passi per la landing
 * 
************************************************ **/

import config from "/src/config/config.json";

export default function setInitialValues() {
  // console.log('*****-> setInitialValues --  --> ');

  // theme
  // ****************************************************************
  // type Theme = 'dark' | 'light'
  let intialTheme = localStorage.getItem("theme");
  // console.log('setInitialValues -- intialTheme --> ', intialTheme);
  if (intialTheme === 'undefined' || intialTheme === null) {
    intialTheme = config.DEFAULT.theme;
    localStorage.setItem("theme", intialTheme);
  }

  // language
  // ****************************************************************
  let lang = localStorage.getItem("lang");
  // console.log('setInitialValues -- lang --> ', lang);
  if (lang === 'undefined' || lang === null) {
    lang = config.DEFAULT.languaje;
    localStorage.setItem("lang", lang);
  }
 
  const initial = {
    intialTheme,
    lang
  };

  // console.log('*****<- setInitialValues -- result --> ', initial);
  return initial;  
}
