
import axios from 'axios';
import config from "/src/config/config";

const apiUrl = (process.env.REACT_APP_SERVER_URL || 'http://localhost:3000/') + config.API.assets;

// ****************************************************************

export const getAssetsFETCH = async () => {
  // console.log('*****-> getAssetsFETCH -- ');

  const response = await fetch(apiUrl, {
    method: "GET", // *GET, POST, PUT, DELETE, etc.
    mode: "cors", // no-cors, *cors, same-origin
    cache: "default", // *default, no-cache, reload, force-cache, only-if-cached
    credentials: "same-origin", // include, *same-origin, omit
    headers: {
      "Content-Type": "application/json",
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
    redirect: "follow", // manual, *follow, error
    referrerPolicy: "no-referrer" //, // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    // body: JSON.stringify(filter), // body data type must match "Content-Type" header
  });

  // console.log('getAssets -- response --> ', response);
  if (!response.ok) {
    console.error('*** ERROR *** -- getAssets -- response --> ', response);
    throw new Error('Network response was not ok');
  }

  return response.json(); 
}

// ****************************************************************

export const getAssetId = async (params) => {
  const { token, id } = params;
  // console.log('*****-> getAsset -- params --> ', params);
  // console.log('getAsset -- apiUrl --> ', apiUrl);

  if (id) {
    const tokenTxt = token?token:'';
    // console.log('getAsset -- tokenTxt --> ', tokenTxt);
  
    const instance = axios.create({
      //baseURL: 'http://localhost:3000',
      headers: {
        'Content-Type': 'application/json',
        'authorization': 'Bearer ' + tokenTxt,
        // 'authorization': `Bearer ${localStorage.getItem('authStorage.state.token')}`
        'application': config.DEFAULT.app_key
      }
    });

    const apiUrlCall = apiUrl +'/'+ id;
    // console.log('getAsset -- apiUrlCall --> ', apiUrlCall);

    const result = await instance.get(apiUrlCall)
    .then( response => {
      // console.log('getAsset -- response.data --> ', response.data);
      return response.data
    })
    .catch(error => {
      console.error('*** ERROR *** -- getAsset -- response --> ', error);
    })
    /* .finally(function () {
      // always executed
    }) */;
  
    // console.log('*****<- getAsset -- result --> ', result);
    return result;
  }
  else return null;
}

// ****************************************************************

export const getAssets = async (params) => {
  const { token, filter} = params;
  // console.log('*****-> getAssets -- params --> ', params);
  // console.log('getAssets -- apiUrl --> ', apiUrl);

  const tokenTxt = token?token:'';
  // console.log('getAssets -- tokenTxt --> ', tokenTxt);

  const instance = axios.create({
    //baseURL: 'http://localhost:3000',
    headers: {
      'Content-Type': 'application/json',
      'authorization': 'Bearer ' + tokenTxt,
      // 'authorization': `Bearer ${localStorage.getItem('authStorage.state.token')}`
      'application': config.DEFAULT.app_key
    }
  });

  const result = await instance.get(apiUrl, {params: filter})
  .then( response => {
    // console.log('getAssets -- response.data --> ', response.data);
    return response.data
  })
  .catch(error => {
    console.error('*** ERROR *** -- getAssets -- response --> ', filter, error);
  })
  /* .finally(function () {
    // always executed
  }) */;

  // console.log('*****<- getAssets -- result --> ', result);
  return result;
}

// ****************************************************************

export const getAssetsRent = async () => {
  // console.log('*****-> getAssetsRent -- ');
  const filter =  {
      type: 'property',
      rent: true
    }
  return getAssets({filter});
}

export const getAssetsLong = async () => {
  // console.log('*****-> getAssetsLong -- ');
  const filter =  {
      type: 'property',
      long: true
    }
  return getAssets({filter});
}

export const getAssetsSale = async () => {
  // console.log('*****-> getAssetsSale -- ');
  const filter =  {
      type: 'property',
      sale: true
    }
  return getAssets({filter});
}

export const getAssetsHotel = async () => {
  // console.log('*****-> getAssetsHotel -- ');
  const filter =  {type: 'hotel'}
  return getAssets({filter});
}

export const getAssetsRealState = async () => {
  // console.log('*****-> getAssetsRealState -- ');
  const filter =  {type: 'real_state'}
  return getAssets({filter});
}

export const getAssetsRestaurant = async () => {
  // console.log('*****-> getAssetsRestaurant -- ');
  const filter =  {type: 'eat'}
  return getAssets({filter});
}

export const getAssetsStore = async () => {
  // console.log('*****-> getAssetsStore -- ');
  const filter =  {type: 'store'}
  return getAssets({filter});
}

export const getAssetsActivity = async () => {
  // console.log('*****-> getAssetsActivity -- ');
  const filter =  {type: 'activity'}
  return getAssets({filter});
}

export const getAssetsService = async () => {
  // console.log('*****-> getAssetsActivity -- ');
  const filter =  {type: 'services'}
  return getAssets({filter});
}
