// ROUTES
// --------------------------------

import App from '/src/App.jsx';
import { lazy } from 'react';
import { createBrowserRouter, createRoutesFromElements, Route, } from "react-router-dom";

// PAGES
// --------------------------------
// landing page
// import LandingPage from '/src/routes/LandingPage.jsx';
const LandingPage = lazy(()=> import('/src/routes/LandingPage.jsx'));
// template
// const PageLayout = lazy(()=> import('/src/components/page/PageLayout'));
const PageLayoutAsset = lazy(()=> import('/src/components/page/PageLayoutAsset.jsx'));
// list pages
const MyBookingsPage = lazy(()=> import('/src/routes/MyBookingsPage.jsx'));
const HotelListPage = lazy(()=> import('/src/routes/HotelListPage.jsx'));
const RealStateListPage = lazy(()=> import('/src/routes/RealStateListPage.jsx'));
const EatListPage = lazy(()=> import('/src/routes/EatListPage.jsx'));
const ShopListPage = lazy(()=> import('/src/routes/ShopListPage.jsx'));
const DoListPage = lazy(()=> import('/src/routes/DoListPage.jsx'));
const ServiceListPage = lazy(()=> import('/src/routes/ServiceListPage.jsx'));
// real state pages
const PropertyListRentPage = lazy(()=> import('/src/routes/PropertyListRentPage.jsx'));
const PropertyListLongPage = lazy(()=> import('/src/routes/PropertyListLongPage.jsx'));
const PropertyListSalePage = lazy(()=> import('/src/routes/PropertyListSalePage.jsx'));
const AssetPage = lazy(()=> import('/src/routes/AssetPage.jsx'));
// info pages
const MapPage = lazy(()=> import('/src/routes/MapPage.jsx'));
const WeatherPage = lazy(()=> import('/src/routes/WeatherPage.jsx'));
// site pages
// import OwnerPage from './OwnerPage.jsx';
const ErrorPage = lazy(()=> import('/src/routes/ErrorPage.jsx'));
const InfoPage = lazy(()=> import('/src/routes/InfoPage.jsx'));
const ContactPage = lazy(()=> import('/src/routes/ContactPage.jsx'));
const UserProfilePage = lazy(()=> import('/src/routes/UserProfilePage.jsx'));

// AUTHORIZATION - LOGIN
// --------------------------------
const Auth = lazy(()=> import('/src/routes/Auth.jsx'));
const PageSideAuth = lazy(()=> import('/src/components/auth/PageSideAuth.jsx'));
const SignInSideBlock = lazy(()=> import('/src/components/auth/SignInSideBlock.jsx'));
const SignOutSideBlock = lazy(()=> import('/src/components/auth/SignOutSideBlock.jsx'));
const SignUpSideBlock = lazy(()=> import('/src/components/auth/SignUpSideBlock.jsx'));
const PasswordChangeSideBlock = lazy(()=> import('/src/components/auth/PasswordChangeSideBlock.jsx'));
const PasswordRememberSideBlock = lazy(()=> import('/src/components/auth/PasswordRememberSideBlock.jsx'));

// LOADERS
// ****************************************************************
import parallelDataLoader from '/src/data/dataLoader/parallelDataLoader.jsx';
import assetDataLoader from '/src/data/dataLoader/assetDataLoader.jsx';
import mapMarkersDataLoader from '/src/data/dataLoader/mapMarkersDataLoader.jsx';
// ****************************************************************

export const router = createBrowserRouter(
  createRoutesFromElements(

      <Route path="/" /* loader={parallelDataLoader} id="root"  */
        element={<App />} errorElement={<ErrorPage />} >
        <Route errorElement={<ErrorPage />} >
          {/* LANDING PAGE */}
          <Route index element={<LandingPage />} />

          {/* AUTHORIZATION - LOGIN */}
          <Route path="auth" element={ <PageSideAuth /> } >
            <Route path="signin" element={<SignInSideBlock /> } />
            <Route path="signout" element={<SignOutSideBlock /> } />
            <Route path="signup" element={<SignUpSideBlock /> } />  
            <Route path="remember" element={<PasswordRememberSideBlock /> } />
            <Route path="change" element={<PasswordChangeSideBlock /> } />
          </Route>

          {/* ASSETS */}
          <Route path="assets" loader={parallelDataLoader} id="assets" element={ <PageLayoutAsset/> } >
            {/* ASSETS LIST */}
            <Route path="propertyListRent" element={ <PropertyListRentPage /> } />
            <Route path="propertyListLong" element={ <PropertyListLongPage /> } />
            <Route path="propertyListSale" element={ <PropertyListSalePage /> } />
            <Route path="hotelList" element={ <HotelListPage /> } />
            <Route path="realStateList" element={ <RealStateListPage /> } />
            <Route path="eatList" element={ <EatListPage /> } />
            <Route path="shopList" element={ <ShopListPage /> } />
            <Route path="doList" element={ <DoListPage /> } />
            <Route path="servicesList" element={ <ServiceListPage /> } />

            {/* ASSET DESCRIPTION */}
            <Route path="asset/:id/:type?" loader={(params) => assetDataLoader(params)}
              id="asset" element={<AssetPage />}  />

          </Route>
        
          {/* USER PAGES */}   
          <Route path="profile" element={ <Auth><UserProfilePage /></Auth> } />
          <Route path="myBookings" element={ <Auth><MyBookingsPage /></Auth> } />

          {/* INFORMACIO - UTILITATS */}
          <Route path="map" id="map" loader={mapMarkersDataLoader} element={ <MapPage /> } />
          <Route path="weather" element={ <WeatherPage /> } />
          <Route path="webcams" component={() => {
            window.location.replace = 'https://www.windy.com/ca/-C%C3%A0meres-web/Espanya/Comunitat-Valenciana/Pen%C3%ADscola/Peniscola-Castle/webcams/1629234914?40.363,-12.788,5';
            return null;
          }}/>

          {/* PAGINES COMPLEMENTARIES */}   
          <Route path="contact" element={ <ContactPage /> } />
          <Route path="info/:index?" element={ <InfoPage /> } />

        </Route>
      </Route>
  )
);
