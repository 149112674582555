
import axios from 'axios';
import config from "/src/config/config";

const apiUrl = (process.env.REACT_APP_SERVER_URL || 'http://localhost:3000/') + config.API.assetsAvailability;

export const getAssetAvailability = async (params) => {
  const { id, token, filter} = params;
    // console.log('*****-> getAssetAvailability -- params --> ', params);
  
    /* const filter =  {
        _id: params.id
      };
    let result = [];  
    result = await axios.get(apiUrl, {params: filter}) */

    let result = [];  

    if (id) {
      const tokenTxt = token?token:'';
      const instance = axios.create({
        //baseURL: 'http://localhost:3000',
        headers: {
          'Content-Type': 'application/json',
          'authorization': 'Bearer ' + tokenTxt,
          // 'authorization': `Bearer ${localStorage.getItem('authStorage.state.token')}`
          'application': config.DEFAULT.app_key
        }
      });

      result = await instance.get(apiUrl+'/'+id) 
      .then( response => {
        // console.log('getAssetsAvailability -- response.data --> ', response.data);
        return response.data
      })
      .catch(error => {
        console.error('*** ERROR *** -- getAssetsAvailability -- response --> ', params, error);
      })
      /* .finally(function () {
        // always executed
      }) */;
    }
  
    // console.log('*****<- getAssetsAvailability -- result --> ', result);
    return result;
  }

