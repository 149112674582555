
import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';

import config from '/src/config/config.json';

type ThemeMode = "light" | "dark";

interface ITheme {
  theme: string

  changeTheme: () => ThemeMode
  initializeTheme: () => ThemeMode
}


export const useThemeStore = create<ITheme>() (
  persist (
    ( (set, get) => ({
      theme: localStorage.getItem("theme")?localStorage.getItem("theme"):config.DEFAULT.theme,

      changeTheme: () => {
        const actualTheme = get().theme;
        let newTheme:ThemeMode;
        // console.log('STORE -- theme -- changeTheme -- oldTheme: ', actualTheme )
        if (actualTheme === 'light') newTheme = 'dark';
          else newTheme = 'light';
        // actualitza el estat
        set( state => ({ ...state, 
          theme: newTheme
          }) );
        // actualitza el tema a CSS  
        document.body.setAttribute('data-theme', newTheme);  
        localStorage.setItem("theme", newTheme);
        // console.log('STORE -- theme -- changeTheme -- to ', newTheme);
        return newTheme;
      },

      initializeTheme: () => {
        // console.log('STORE -- theme -- initializeTheme -- ');
        let newTheme:ThemeMode = localStorage.getItem("theme")?localStorage.getItem("theme"):config.DEFAULT.theme;
        set( state => ({ ...state, 
          theme: newTheme
          }) );
        document.body.setAttribute('data-theme', newTheme);  
        localStorage.setItem("theme", newTheme);
        // console.log('STORE -- theme -- changeTheme -- to ', newTheme);
        return newTheme;
      }

    }))
    , { 
        name: 'themeStorage',  // name of the item in the storage (must be unique)
        storage: createJSONStorage(() => localStorage), // (optional) by default, 'localStorage' is used   localStorage | sessionStorage
      } )  
);
