
import { createTheme } from "@mui/material";

export const inputBox = createTheme({
/*   components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          // Outlined
          "& .MuiOutlinedInput-root": {
            color: "#000",
            fontFamily: "Arial",
            fontWeight: "bold",
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "#2e2e2e",
              borderWidth: "2px",
            },
            "&.Mui-focused": {
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "secondary.main",
                borderWidth: "3px",
              },
            },
            "&:hover:not(.Mui-focused)": {
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: "#ccc",
              },
            },
          },
          "& .MuiInputLabel-outlined": {
            color: "#2e2e2e",
            fontWeight: "bold",
            "&.Mui-focused": {
              color: "secondary.main",
            },
          },
          // Filled
          "& .MuiFilledInput-root": {
            color: "#000",
            fontFamily: "Arial",
            fontWeight: "bold",
            backgroundColor: "#e7e7e7",
            borderTopLeftRadius: "7px",
            borderTopRightRadius: "7px",
            "&:before": {
              borderColor: "#2e2e2e",
              borderWidth: "2px",
            },
            "&:after": {
              borderColor: "secondary.main",
              borderWidth: "3px",
            },
            ":hover:not(.Mui-focused)": {
              "&:before": {
                borderColor: "#e7e7e7",
                borderWidth: "2px",
              },
              backgroundColor: "#f4f4f4",
            },
          },
          "& .MuiInputLabel-filled": {
            color: "#2e2e2e",
            fontWeight: "bold",
            "&.Mui-focused": {
              color: "secondary.main",
            },
          },
          // Standard
          "& .MuiInput-root": {
            color: "#000",
            fontFamily: "Arial",
            fontWeight: "bold",
            "&:before": {
              borderColor: "#2e2e2e",
              borderWidth: "2px",
            },
            "&:after": {
              borderColor: "secondary.main",
              borderWidth: "3px",
            },
            ":hover:not(.Mui-focused)": {
              "&:before": {
                borderColor: "#e7e7e7",
                borderWidth: "2px",
              },
            },
          },
        },
      },
    },
  }, */
  
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        // fa que el texte de autorelleno no sigui del color del fondo
        input: {
          "&:-webkit-autofill": {
            "-webkit-box-shadow": "0 0 0 100px var(--primary-weak) inset",
            "-webkit-text-fill-color": "var(--text-primary)",
          },
        },
      },
    },
  },

});

export const light = createTheme({
  palette: {
    mode: 'light',
  },
});

export const dark = createTheme({
    palette: {
      mode: 'dark',
    },
  });

export  const buttonsBlueLight = createTheme({
    palette: {
      primary: {
        main: "#0000FF"
      },
/*       secondary: {
        main: "#ffcc80"
      } */
    }
  });  

export  const buttonsBlueDark = createTheme({
    palette: {
      primary: {
        main: "#00FFFF"
      },
/*       secondary: {
        main: "#ffcc80"
      } */
    }
  });
  