
import axios from 'axios';
import config from "/src/config/config";

const apiUrl = (process.env.REACT_APP_SERVER_URL || 'http://localhost:3000/') + config.API.mapMarkers;

export async function getmapMarkers(params) {
  const { token, filter} = params;
// export const getmapMarkers = async () => {
  //console.log('*****-> getmapMarkers --> ', ' -- apiUrl -->', apiUrl);
  /* const filter =  {
      _id: params.id,
      lang: params.lang
    } */

  try {
    const tokenTxt = token?token:'';
    const instance = axios.create({
      //baseURL: 'http://localhost:3000',
      headers: {
        'Content-Type': 'application/json',
        'authorization': 'Bearer ' + tokenTxt,
        // 'authorization': `Bearer ${localStorage.getItem('authStorage.state.token')}`
        'application': config.DEFAULT.app_key
      }
    });

    const result = await instance.get(apiUrl /* , {params: filter} */)
    .then( response => {
      //console.log('getmapMarkers -- response.data --> ', response.data);
      return response.data
    })
    .catch(error => {
      console.error('*** ERROR *** -- getmapMarkers -- response --> ', error);
    })
      /* .finally(function () {
    // always executed
    }) */;
    // console.log('*****<- getmapMarkers -- result --> ', result);
    return result;
  } catch (error) {
    console.log('*** ERROR *** -- getmapMarkers -- error --> ', error);
  }
}
