
import axios from 'axios';
import config from "/src/config/config";

const apiUrl = (process.env.REACT_APP_SERVER_URL || 'http://localhost:3000/') + config.API.assetsDesc;

export const getAssetDesc = async (params) => {
    const { id, lang, token} = params;
    // console.log('*****-> getAssets -- params --> ', params);
  
    const filter =  {
        asset_id: id,
        lang: lang
      };

    //console.log('getAssetsDesc -- filter: ', filter);
    //console.log('getAssetsDesc -- apiUrl: ', apiUrl);

    const tokenTxt = token?token:'';
    const instance = axios.create({
      //baseURL: 'http://localhost:3000',
      headers: {
        'Content-Type': 'application/json',
        'authorization': 'Bearer ' + tokenTxt,
        // 'authorization': `Bearer ${localStorage.getItem('authStorage.state.token')}`
        'application': config.DEFAULT.app_key
      }
    });

    const result = await instance.get(apiUrl, {params: filter})
      .then( response => {
        // console.log('getAssetsDesc -- response.data --> ', response.data);
        return response.data
      })
      .catch(error => {
        console.error('*** ERROR *** -- getAssetDesc -- response --> ', filter, error);
      })
      /* .finally(function () {
        // always executed
      }) */;
  
    // console.log('getAssets -- result --> ', result);
    return result;
  }
