// Copyright component
import PropTypes from 'prop-types';
import { RotatingLines } from  'react-loader-spinner';
import { Link } from "react-router-dom";

export default function Loader(props) {
  return (
    <>
    <div style={{
      height: '100vh', /* suponiendo que quieres centrar respecto a todo el alto de la pantalla */
      width: '100%',
      display: 'flex', /* propiedad flexbox */
      justifyContent: 'center', /* centra el contenido horizontalmmente */
      alignItems: 'center' /* centra el contenido verticalmente */
    }}>
        <RotatingLines
          strokeColor="orange"  // default: "grey"
          strokeWidth="5" // default: "5"
          animationDuration="1.50" // default: "0,75"
          width="125" // default: "96"
          visible={true}
        />
    </div>  
    <div>
        {props.children}
    </div>
    </>
  );
}

Loader.propTypes = {
    children: PropTypes.element
  }
